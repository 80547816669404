<template>
  <b-modal
    :id="`detail-config-company-modal`"
    size="lg"
    centered
    no-close-on-backdrop
    header-bg-variant="light-info"
    hide-footer
    :title="$t('sepay.columns.details')"
  >
    <IAmOverlay :loading="loading">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group :label="$t('sepay.form.paymentCode')">
              <b-form-checkbox
                :checked="item.paymentCode === 'on'"
                switch
                inline
                @change="value => {
                  item.paymentCode = value ? 'on' : 'off';
                }"
              >
                {{ $t('sepay.form.ph.paymentCode') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group :label="$t('sepay.form.paymentCodePrefix')">
              <b-form-input
                v-model="item.paymentCodePrefix"
                :placeholder="$t('sepay.form.ph.paymentCodePrefix')"
                class="bg-white"
                @blur="() => {
                  item.paymentCodePrefix = item.paymentCodePrefix.replace(/\d+/g, '').slice(0, 3);
                }"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group :label="$t('sepay.form.paymentCodeSuffixFrom')">
              <b-form-input
                v-model="item.paymentCodeSuffixFrom"
                :placeholder="$t('sepay.form.ph.paymentCodeSuffixFrom')"
                class="bg-white"
                :type="`number`"
                @blur="() => {
                  item.paymentCodeSuffixFrom = Number(item.paymentCodeSuffixFrom);
                }"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group :label="$t('sepay.form.paymentCodeSuffixTo')">
              <b-form-input
                v-model="item.paymentCodeSuffixTo"
                :placeholder="$t('sepay.form.ph.paymentCodeSuffixTo')"
                class="bg-white"
                :type="`number`"
                @blur="() => {
                  item.paymentCodeSuffixTo = Number(item.paymentCodeSuffixTo)
                }"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group :label="$t('sepay.form.paymentCodeSuffixCharacterType')">
              <v-select
                v-model="item.paymentCodeSuffixCharacterType"
                :placeholder="$t('sepay.form.ph.paymentCodeSuffixCharacterType')"
                :options="['NumberAndLetter', 'NumberOnly']"
                :clearable="false"
              >
                <template #option="data">
                  <span>
                    {{ $t('sepay.suffix.' + data.label) }}
                  </span>
                </template>

                <template #selected-option="data">
                  <span>
                    {{ $t('sepay.suffix.' + data.label) }}
                  </span>
                </template>

                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group :label="$t('sepay.form.transactionAmount')">
              <b-form-checkbox
                :checked="item.transactionAmount === 'Unlimited'"
                switch
                inline
                @change="value => {
                  item.transactionAmount = value ? 'Unlimited' : 0;
                }"
              >
                {{ $t('sepay.unlimited') }}
              </b-form-checkbox>

              <b-form-input
                v-if="item.transactionAmount !== 'Unlimited'"
                v-model="item.transactionAmount"
                :placeholder="$t('sepay.form.ph.transactionAmount')"
                class="bg-white mt-1"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <div class=" d-flex-center gap-3">
        <b-button
          variant="info"
          @click="saveCompany"
        >
          {{ $t('save') }}
        </b-button>
        <b-button
          variant="secondary"
          @click="() => this.$bvModal.hide('detail-config-company-modal')"
        >
          {{ $t('cancel') }}
        </b-button>
      </div>
    </IAmOverlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormCheckbox,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    item: {
      type: Object,
      default: null,
      required: false,
    },
    status: {
      type: Array,
      default: null,
    },
  },

  setup(props, { emit }) {
    async function saveCompany() {
      await emit('save')
      this.$bvModal.hide('detail-config-company-modal')
    }
    return { saveCompany }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
