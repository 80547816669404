var render = function () {
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "detail-config-company-modal",
      "size": "lg",
      "centered": "",
      "no-close-on-backdrop": "",
      "header-bg-variant": "light-info",
      "hide-footer": "",
      "title": _vm.$t('sepay.columns.details')
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('sepay.form.paymentCode')
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "checked": _vm.item.paymentCode === 'on',
      "switch": "",
      "inline": ""
    },
    on: {
      "change": function change(value) {
        _vm.item.paymentCode = value ? 'on' : 'off';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('sepay.form.ph.paymentCode')) + " ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('sepay.form.paymentCodePrefix')
    }
  }, [_c('b-form-input', {
    staticClass: "bg-white",
    attrs: {
      "placeholder": _vm.$t('sepay.form.ph.paymentCodePrefix')
    },
    on: {
      "blur": function blur() {
        _vm.item.paymentCodePrefix = _vm.item.paymentCodePrefix.replace(/\d+/g, '').slice(0, 3);
      }
    },
    model: {
      value: _vm.item.paymentCodePrefix,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "paymentCodePrefix", $$v);
      },
      expression: "item.paymentCodePrefix"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('sepay.form.paymentCodeSuffixFrom')
    }
  }, [_c('b-form-input', {
    staticClass: "bg-white",
    attrs: {
      "placeholder": _vm.$t('sepay.form.ph.paymentCodeSuffixFrom'),
      "type": "number"
    },
    on: {
      "blur": function blur() {
        _vm.item.paymentCodeSuffixFrom = Number(_vm.item.paymentCodeSuffixFrom);
      }
    },
    model: {
      value: _vm.item.paymentCodeSuffixFrom,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "paymentCodeSuffixFrom", $$v);
      },
      expression: "item.paymentCodeSuffixFrom"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('sepay.form.paymentCodeSuffixTo')
    }
  }, [_c('b-form-input', {
    staticClass: "bg-white",
    attrs: {
      "placeholder": _vm.$t('sepay.form.ph.paymentCodeSuffixTo'),
      "type": "number"
    },
    on: {
      "blur": function blur() {
        _vm.item.paymentCodeSuffixTo = Number(_vm.item.paymentCodeSuffixTo);
      }
    },
    model: {
      value: _vm.item.paymentCodeSuffixTo,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "paymentCodeSuffixTo", $$v);
      },
      expression: "item.paymentCodeSuffixTo"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('sepay.form.paymentCodeSuffixCharacterType')
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": _vm.$t('sepay.form.ph.paymentCodeSuffixCharacterType'),
      "options": ['NumberAndLetter', 'NumberOnly'],
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('sepay.suffix.' + data.label)) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('sepay.suffix.' + data.label)) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.item.paymentCodeSuffixCharacterType,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "paymentCodeSuffixCharacterType", $$v);
      },
      expression: "item.paymentCodeSuffixCharacterType"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('sepay.form.transactionAmount')
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "checked": _vm.item.transactionAmount === 'Unlimited',
      "switch": "",
      "inline": ""
    },
    on: {
      "change": function change(value) {
        _vm.item.transactionAmount = value ? 'Unlimited' : 0;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('sepay.unlimited')) + " ")]), _vm.item.transactionAmount !== 'Unlimited' ? _c('b-form-input', {
    staticClass: "bg-white mt-1",
    attrs: {
      "placeholder": _vm.$t('sepay.form.ph.transactionAmount')
    },
    model: {
      value: _vm.item.transactionAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "transactionAmount", $$v);
      },
      expression: "item.transactionAmount"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('div', {
    staticClass: " d-flex-center gap-3"
  }, [_c('b-button', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": _vm.saveCompany
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('save')) + " ")]), _c('b-button', {
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": function click() {
        return _this.$bvModal.hide('detail-config-company-modal');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }